/** @jsx jsx */
import { jsx, Button } from "theme-ui"
import HomeLayout from "../components/home-layout"
import { baseColors } from "@theme-ui/preset-tailwind"
import { SEO } from "gatsby-theme-swhwsys-core"

import { Link } from 'gatsby';

const About = () => (
	<HomeLayout>
		<SEO title="About" />

		<h2>Software Hardware Systems is a DBA for Itrel Monroe</h2>

		<p>
			It gives me joy to write software where interacting with a computer over
			here results in buzzes, clicks, whirs, kerchunks, and stuff on the system
			over there. Even more so when the software is modular, unit tested, easy
			to understand, and easy to modify.&nbsp;
			<a href="https://en.wikipedia.org/wiki/SOLID">SOLID</a> is the appropriate
			acronym!
		</p>

		<div sx={{ display: "grid", placeItems: "center" }}>
        <Button
          as={Link}
          sx={{
            backgroundImage:
              "radial-gradient( circle farthest-corner at 10% 20%,  rgba(253,193,104,1) 0%, rgba(251,128,128,1) 90% )",
            color: baseColors.gray[8],
            fontWeight: "bold",
            boxShadow: "default",
            transition: "all 0.3s ease",
            ":hover, :focus, :active": {
              boxShadow: "lg",
              backgroundImage:
                "radial-gradient( circle farthest-corner at 30% 40%,  rgba(253,193,80,1) 0%, rgba(251,128,100,1) 95% )",
            },
          }}
          to="/"
        >
          Home
        </Button>
      </div>
	</HomeLayout>
);

export default About;
